import { Directive, ElementRef, HostListener } from '@angular/core';

import { PathService } from '../../../_core/services/path.service';


@Directive({
  // tslint:disable-next-line: directive-selector
  selector: 'a[routerLink]'
})

export class InternalLinkDirective {
  private elt: ElementRef;

  constructor(
    private pathService: PathService,
    elt: ElementRef
  ) {
    this.elt = elt;
  }

}
