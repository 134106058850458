import { Component, ElementRef, HostListener, Inject, LOCALE_ID, OnInit, PLATFORM_ID, Renderer2, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { WINDOW } from '@ng-toolkit/universal';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})

export class HeaderComponent implements OnInit {
  @ViewChild('nav') navElt: ElementRef;
  @ViewChild('sidenav') sidenavElt: ElementRef;
  @ViewChild('sidenavTrigger') sidenavTriggerElt: ElementRef;

  private availablesLanguages = [
    { code: 'fr', label: 'Français' },
    { code: 'en', label: 'English' }
  ];

  public languages = [];
  public currentPath = '/';

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(LOCALE_ID) public localeId: string,
    @Inject(WINDOW) private window: Window,
    private renderer: Renderer2,
    private router: Router
  ) {
    this.availablesLanguages.forEach(lang => {
      if (lang.code === LOCALE_ID.toString()) {
        this.languages.unshift(lang);
      } else {
        this.languages.push(lang);
      }
    });
  }

  ngOnInit() {
    // exposes url into currentPath
    this.router.events.subscribe((_: NavigationEnd) => {
      if (typeof _.url !== 'undefined') {
        this.currentPath = _.url;
      }
    });
  }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    if (this.window.pageYOffset >= 100) {
      this.renderer.addClass(this.navElt.nativeElement, 'scrolled-nav');
    } else {
      this.renderer.removeClass(this.navElt.nativeElement, 'scrolled-nav');
    }
  }
}
